import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import ConfigurationService from 'src/configuration.service';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class PortegoService {
  serviceUrl: string;
  httpOptions: { headers: HttpHeaders; };

  constructor(private http: HttpClient, private config: ConfigurationService) {
    this.serviceUrl = config.getPortegoWebserviceUrl();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }

  getNamesOfTheDay() {
    return this.http.get('assets/data/nameDays.json');
  }
  getSloganOfTheWeek() {
    return this.http.get('assets/data/sloganOfTheWeek.json');
  }
  getContacts(idBuilding: number) {
    const method = 'buildingannouncements/ContactService/GetAllForBuilding/?idBuilding=';
    return this.http.get(this.serviceUrl + method + idBuilding);
  }
  getAnnouncements(idBuilding: number, keycode: string) {
    const method = 'buildingannouncements/AnnouncementService/GetAllForBuilding/?';
    const parameter = !isNullOrUndefined(keycode) ? 'idBuilding=' + idBuilding + '&keycode=' + keycode : 'idBuilding=' + idBuilding;

    return this.http.get(this.serviceUrl + method + parameter);
  }
  getBuildings(value: string) {
    const method = 'buildingannouncements/BuildingService/Search?value=';
    return this.http.get(this.serviceUrl + method + value);
  }
  getBuildingAdress(idBuilding: number) {
    const method = 'buildingannouncements/BuildingService/Get/?idBuilding=';
    return this.http.get(this.serviceUrl + method + idBuilding);
  }

  getAppointments(idBuilding: number, keycode: string) {
    const method = 'bat/InfoboardService/GetAppointmentsForBuilding/?';
    const parameter = !isNullOrUndefined(keycode) ? 'idBuilding=' + idBuilding + '&keycode=' + keycode : 'idBuilding=' + idBuilding;
    return this.http.get(this.serviceUrl + method + parameter);
  }
  getHouseRules(idBuilding: number) {
    const method = 'buildingannouncements/GeneralContentService/GetAllForBuilding/';
    return this.http.get(this.serviceUrl + method, {
      params: {
        idBuilding: idBuilding.toString()
      }
    });
  }
  getIdBuildingFromBuildingNumber(buildingNumber: string) {
    const method = 'buildingannouncements/BuildingService/GetIdBuildingFromBuildingNumber/';
    return this.http.get<number>(this.serviceUrl + method, {
      params: {
        buildingNumber: buildingNumber
      }
    });
  }
  existsBuildingWithId(idBuilding: number) {
    const method = 'buildingannouncements/BuildingService/Exists/';
    return this.http.get<boolean>(this.serviceUrl + method, {
      params: {
        idBuilding: idBuilding.toString()
      }
    });
  }
  createSubscription(email: string, idBuilding: number, keycode: string) {
    const method = 'buildingannouncements/SubscriptionService/CreateSubscription/';
    const data = new HttpParams()
      .set('email', email)
      .set('idBuilding', idBuilding.toString())
      .set('keycode', keycode);
    return this.http.post(this.serviceUrl + method, data, this.httpOptions);
  }
  activateSubscription(subscriptionGuid: string) {
    const method = 'buildingannouncements/SubscriptionService/ActivateSubscription/';
    const data = new HttpParams()
      .set('subscriptionGuid', subscriptionGuid);
    return this.http.post(this.serviceUrl + method, data, this.httpOptions);
  }
  deleteSubscription(subscriptionGuid: string) {
    const method = 'buildingannouncements/SubscriptionService/DeleteSubscription/';
    const data = new HttpParams()
      .set('subscriptionGuid', subscriptionGuid);
    return this.http.post(this.serviceUrl + method, data, this.httpOptions);
  }
}
