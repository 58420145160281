import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ContactModel } from 'src/app/models/contacts/contact-model';
import { KioskModeService } from 'src/app/services/kiosk-mode.service';

@Component({
  selector: 'app-contact-tile',
  templateUrl: './contact-tile.component.html',
  styleUrls: ['./contact-tile.component.less']
})
export class ContactTileComponent implements OnInit {
  @Input() model: ContactModel;
  constructor(private kioskModeService: KioskModeService) { }

  ngOnInit() { }

  @HostListener('click', ['$event'])
  onClick(event) {
    if (!this.kioskModeService.isKioskMode) {
      // When not in kiosk mode, do everything as normal. Don't intercept click
      return true;
    }

    if (event.target.tagName === 'A' && event.target.href && event.target.href.startsWith('http')) {
      this.kioskModeService.openModal(event.target.href);
    }

    // returning false here is equal to event.preventDefault()
    return false;
  }

  isNullOrUndefined<T>(obj?: T | null): boolean {
    // null == undefined so this is true if obj = null or obj = undefined
    return obj == null;
  }
}
