import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PortegoService } from 'src/app/services/portego.service';
import { SubscriptionModel } from 'src/app/models/subscription-model';
import { HttpErrorResponse } from '@angular/common/http';
import { timer } from 'rxjs';

@Component({
  selector: 'app-subscribe-tile',
  templateUrl: './subscribe-tile.component.html',
  styleUrls: ['./subscribe-tile.component.less']
})
export class SubscribeTileComponent implements OnInit {
  @Input() idBuilding: number;
  @ViewChild('subscribeForm', {static: false}) subscribeForm: NgForm;
  complete: boolean;
  model: SubscriptionModel;
  error: boolean;
  errorMessage: string;

  constructor(private portegoService: PortegoService) {
    this.model = { email: null, acceptPrivacyPolicy: false };
  }

  ngOnInit() {
    this.complete = false;
    this.error = false;
  }

  onSubmit(form: NgForm) {
    if (form.valid !== true) { return; }

    this.portegoService.createSubscription(this.model.email, this.idBuilding, null).subscribe(
      () => {
        this.complete = true;
        this.resetFormAfterMilliseconds(10_000);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.resetFormAfterMilliseconds(10_000);
        this.error = true;
        if (error.status === 409 /* Error Code: Conflict */) {
          this.errorMessage = error.statusText;
        }
      },
    );
  }

  private resetFormAfterMilliseconds(msec: number = 10_000) {
    timer(msec).subscribe(() => {
      this.ngOnInit(); 
      this.subscribeForm.resetForm();
    });
  }
}
