import { EContactType } from '../e-contact-type.enum';

export class ContactModel {
    id: number;
    additionalInfo: string;
    company: string;
    email: string;
    eType: EContactType;
    logoBinary: [];
    logoBase64: string;
    mobilePhone: string;
    name: string;
    phoneNumber: string;
    photoBinary: [];
    photoBase64: string;
    position: string;
    role: string;
    type: string;
    url: string;
    urlLabel: string;
    constructor() {
   }
}
