
export class TemperatureModel {
    day: number;
    night: number;
    eve: number;
    morn: number;
    max: number;
    constructor() {
    }
}
