import { FileModel } from './file-model';

export class AppointmentModel {
  id: number;
  attachment: FileModel;
  backButton: boolean;
  content: string;
  date: Date;
  dateLabel: string;
  title: string;
  subTitle: string;
  highlighted: boolean;
  appointmentTypeCode: string;
  typeIconUrl: string;
}
