import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KioskModalComponent } from '../components/kiosk-modal/kiosk-modal.component';

@Injectable({
  providedIn: 'root',
})
export class KioskModeService {

  private _isKioskMode = false;
  public get isKioskMode(): boolean {
    return this._isKioskMode;
  }

  constructor(
    private modalService: NgbModal
  ) {
    const storedIsKioskMode = localStorage.getItem('isKioskMode');
    if (storedIsKioskMode == null) { return; }
    this._isKioskMode = storedIsKioskMode.toLowerCase() === 'true';
  }

  activateKioskMode() {
    if (!this.isKioskMode) {
      this._isKioskMode = true;
      localStorage.setItem('isKioskMode', this._isKioskMode.toString());
    }
  }

  openModal(url: string) {
    let ref = this.modalService.open(KioskModalComponent, { centered: false, size: 'xl', windowClass: 'phone-modal' });
    ref.componentInstance.url = url;
  }
}
