import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-qrcode-tile',
  templateUrl: './qrcode-tile.component.html',
  styleUrls: ['./qrcode-tile.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class QrcodeTileComponent implements OnInit {
  @Input() public url?: string;

  constructor() { }

  ngOnInit() {
    if(typeof this.url === 'undefined') {
      this.url = location.href;
    }
  }

}
