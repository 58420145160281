import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { BuildingSearchComponent } from './views/building-search/building-search.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ImprintComponent } from './views/imprint/imprint.component';
import { SubscriptionComponent } from './views/subscription/subscription.component';
import { KioskModeComponent } from './views/kiosk-mode/kiosk-mode.component';

const routes: Routes = [
  {
    path: '',
    component: BuildingSearchComponent,
    pathMatch: 'full'
  },
  {
    path: 'kiosk',
    component: KioskModeComponent
  },
  {
    path: 'search',
    component: BuildingSearchComponent
  },
  {
    path: 'board',
    redirectTo: ''
  },
  {
    path: 'demo',
    pathMatch: 'full',
    component: HomeComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'imprint',
    component: ImprintComponent
  },
  {
    path: 'subscribe/:idBuilding/:subscriptionGuid',
    component: SubscriptionComponent
  },
  {
    path: 'unsubscribe/:idBuilding/:subscriptionGuid',
    component: SubscriptionComponent
  },
   /* Use least-specific routes last. Angular takes the first matching route thaz consumes most of the URL., and any route would match ":id"
   but "/346/x" would prefer :id/:keycode since it consumes more of the URL */
  {
    path: ':boardKey',
    component: HomeComponent
  },
  {
    path: ':boardKey/:keycode',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
