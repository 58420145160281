import { Component, OnInit } from '@angular/core';
import { PortegoService } from 'src/app/services/portego.service';
import { ActivatedRoute } from '@angular/router';
import { BuildingAddressModel } from 'src/app/models/building-address-model';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.less']
})
export class SubscriptionComponent implements OnInit {
  buildingLabel: string;
  idBuilding: number;
  subscriptionGuid: string;
  success: boolean;
  isSubscribe: boolean;

  constructor(private portegoService: PortegoService, private route: ActivatedRoute) {
    this.success = false;
    this.idBuilding = this.route.snapshot.params.idBuilding;
    this.subscriptionGuid = this.route.snapshot.params.subscriptionGuid;
    this.portegoService.getBuildingAdress(this.idBuilding).subscribe(data => {
      this.buildingLabel = (data as BuildingAddressModel).label;
    });
    this.isSubscribe = this.route.snapshot.routeConfig.path.startsWith('subscribe/');
  }

  ngOnInit() {
    if (this.isSubscribe) {
      this.portegoService.activateSubscription(this.subscriptionGuid).subscribe(
        () => this.success = true,
        () => this.success = false
      );
    } else {
      this.portegoService.deleteSubscription(this.subscriptionGuid).subscribe(
        () => this.success = true,
        () => this.success = false
      );
    }
  }
}
