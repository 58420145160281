import { Component, OnInit, Input } from '@angular/core';
import { SlideInfoModel } from 'src/app/models/slideInfo-model';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.less']
})
export class TileComponent implements OnInit {
  @Input() headline: string;
  @Input() idBuilding: number;
  @Input() slideInfo?: SlideInfoModel;
  constructor() { }

  ngOnInit() {
  }

}
