import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { BuildingSearchModel } from 'src/app/models/buildings/building-search-model';
import { PortegoService } from 'src/app/services/portego.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-building-search',
  templateUrl: './building-search.component.html',
  styleUrls: ['./building-search.component.less']
})
export class BuildingSearchComponent implements OnInit {
  buildings: BuildingSearchModel[];
  value: string;
  constructor(private portegoService: PortegoService, private router: Router) { }

  ngOnInit() {
    const boardKey = localStorage.getItem('board-key');
    if (!isNullOrUndefined(boardKey)) {
      this.navigateToBoard(boardKey);
    } else if (environment.singleBuildingModeBoardKey) {
      this.navigateToBoard(environment.singleBuildingModeBoardKey);
    }
  }

  onKeyUp(event) {
    if (event.key === 'Backspace' || this.value.length > 2) {
      this.portegoService.getBuildings(this.value).subscribe(data => {
        this.buildings = data as BuildingSearchModel[];
      });
    }
  }

  boardClick(building: BuildingSearchModel) {
    let boardKey: number | string;
    boardKey = environment.useBuildingNumberAsBoardKey === true ? building.number : building.id;
    localStorage['board-key'] = boardKey;
    this.navigateToBoard(boardKey);
  }

  navigateToBoard(boardKey: string | number) {
    return this.router.navigate([boardKey]);
  }
}
