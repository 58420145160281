export enum EAnnouncementType {
  information = 1,
  warning = 2,
  livingTogether = 3,
  parkingUnit = 4,
  waste = 5,
  safety = 6,
  hint = 7,
  topicOfTheMonth = 8
}
