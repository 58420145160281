import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppointmentModel } from 'src/app/models/appointment-model';

@Component({
  selector: 'app-appointment-list-tile',
  templateUrl: './appointment-list-tile.component.html',
  styleUrls: ['./appointment-list-tile.component.less']
})
export class AppointmentListTileComponent implements OnInit {
  @Input() models: AppointmentModel[];
  @Output() moreClick = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  moreClicked(slideId: string) {
    this.moreClick.emit(slideId);
  }

}
