export class DateTime {
  monthNames: string[];
  days: string[];
  constructor() {
      this.monthNames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli',
      'August', 'September', 'Oktober', 'November', 'Dezember'];
      this.days = [
        'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
  }
  getFormattedDate(date: Date) {
    return date.getDate()  + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
  }
  getCurrentDate(): string {
      const today = new Date();
      const day = today.getDate();
      const month = today.getMonth() + 1;
      const year = today.getFullYear();

      let formattedDay = day.toString();
      if (day < 10) {
          formattedDay = `0${day}`;
      }
      const date = `${this.days[today.getDay()]}, ${formattedDay}. ${this.monthNames[month - 1]} ${year}`;
      return date;
  }
  getCurrentWeekNumber() {
    const date = new Date();
    const thisDay = date.getDate();

    const newDate = date;
    newDate.setDate(1);
    const digit = newDate.getDay();
    const q = (thisDay + digit) / 7;
    const r = (thisDay + digit) % 7;

    if (r !== 0) {
        return Math.ceil(q);
    } else {
        return q;
    }

 }
}
