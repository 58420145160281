import { Injectable } from '@angular/core';
import { FileModel } from '../models/file-model';
import { KioskModeService } from './kiosk-mode.service';

@Injectable({
  providedIn: 'root'
})

export class DownloadService {
  constructor(private kioskModeService: KioskModeService) {

  }

  downloadFile(model: FileModel) {
    if (this.kioskModeService.isKioskMode) {
      this.kioskModeService.openModal(model.fileUrl);
      return;
    }

    fetch(model.fileUrl)
      .then(resp => resp.blob())
      .then(blob => {
        const localBlobUrl = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        document.body.appendChild(anchor);
        anchor.setAttribute('style', 'display: none');
        anchor.href = localBlobUrl;
        anchor.target = '_blank';
        anchor.download = model.name;
        anchor.click();
        window.URL.revokeObjectURL(localBlobUrl);
        document.body.removeChild(anchor);
      })
      .catch((error) => console.error(error));
  }

  convertBase64ToByteArray(value: string): Uint8Array {
    const raw = window.atob(value);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));
    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }
}
