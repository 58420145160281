import { Injectable } from '@angular/core';
import { EAnnouncementType } from '../models/eannouncement-type.enum';

@Injectable({
  providedIn: 'root'
})
export class AnouncementIconService {
  iconPath: string;
  constructor() {
    this.iconPath = '../../assets/images/newsicons/';
  }

  getIconPath(enumType: EAnnouncementType) {
    switch (enumType) {
      case EAnnouncementType.information:
        return this.iconPath + 'information.png';
      case EAnnouncementType.livingTogether:
        return this.iconPath + 'livingTogether.png';
      case EAnnouncementType.waste:
        return this.iconPath + 'waste.png';
      case EAnnouncementType.parkingUnit:
        return this.iconPath + 'parkingUnit.png';
      case EAnnouncementType.warning:
        return this.iconPath + 'warning.png';
      case EAnnouncementType.safety:
        return this.iconPath + 'safety.png';
      case EAnnouncementType.hint:
        return this.iconPath + 'hint.png';
      case EAnnouncementType.topicOfTheMonth:
        return this.iconPath + 'topicOfTheMonth.png';
      default:
        return this.iconPath + 'news_icon.png';
    }
  }
}
