import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {
  isSingleBuildingMode: boolean;

  constructor(private router: Router) { }

  ngOnInit() {
    this.isSingleBuildingMode = environment.singleBuildingModeBoardKey !== undefined && environment.singleBuildingModeBoardKey !== null;
  }

  searchClicked() {
    const boardKey = localStorage.getItem('board-key');
    if (!isNullOrUndefined(boardKey)) {
      localStorage.removeItem('board-key');
    }
    this.router.navigate(['search']);
  }

}
