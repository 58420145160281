import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-links-tile',
  templateUrl: './links-tile.component.html',
  styleUrls: ['./links-tile.component.less']
})
export class LinksTileComponent implements OnInit {
  links: [];
  constructor() {
  }

  ngOnInit() {
    this.links = environment.links as [];
  }

}
