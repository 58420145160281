import { Component, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';

@Component({
  selector: 'app-privacy-banner',
  templateUrl: './privacy-banner.component.html',
  styleUrls: ['./privacy-banner.component.less']
})
export class PrivacyBannerComponent implements OnInit {
  isEnabled: boolean;
  constructor(private router: Router) { }
  ngOnInit() {
    const privacy = localStorage.getItem('privacy-banner-key');
    this.isEnabled = isNullOrUndefined(privacy);

  }
  closeClicked() {
    localStorage['privacy-banner-key'] = false;
  }
  privacyPolicyClicked() {
    this.router.navigate(['/privacy-policy']);
    return false;
  }
 }
