import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import ConfigurationService from 'src/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class OpenWeatherMap {
  forecastDays: number;
  appId: string;
  serviceUrl: string;
  serviceParams: string;
  constructor(private http: HttpClient, private config: ConfigurationService) {
    this.forecastDays = 1;
    this.appId = config.getOpenWeatherMapApiKey();
    this.serviceUrl = 'https://api.openweathermap.org/data/2.5';
    this.serviceParams = 'units=metric&lang=de';
  }
  getData(city: string) {
    return Promise.all([this.getCurrentTemperature(city)/*, this.getForecastForToday(city)*/]);
  }
  getCurrentTemperature(city: string) {
    const url = `${this.serviceUrl}/weather?q=${city}&APPID=${this.appId}&${this.serviceParams}`;
    return this.http.get(url).toPromise();
  }

  getForecastForToday(city: string) {
    const url = `${this.serviceUrl}/forecast/daily?q=${city}&cnt=${this.forecastDays}&APPID=${this.appId}&${this.serviceParams}`;
    return this.http.get(url).toPromise();
  }

  getIconPath(id: number) {
    switch (id) {
      case 800:
        return 'wi-day-sunny';
      case 200:
      case 201:
      case 202:
      case 210:
      case 211:
      case 221:
      case 230:
      case 231:
      case 232:
        return 'wi-strong-wind';
      case 300:
      case 301:
      case 302:
      case 310:
      case 311:
      case 312:
      case 313:
      case 314:
      case 321:
        return 'wi-rain';
      case 500:
      case 501:
      case 502:
      case 503:
      case 504:
      case 511:
      case 520:
      case 521:
      case 522:
      case 531:
        return 'wi-showers';
      case 600:
      case 601:
      case 602:
      case 611:
      case 612:
      case 615:
      case 616:
      case 620:
      case 621:
      case 622:
        return 'wi-snow';
      case 802:
      case 803:
      case 804:
      case 701:
      case 711:
      case 721:
      case 731:
      case 741:
      case 751:
      case 761:
      case 762:
      case 771:
      case 781:
        return 'wi-cloudy';
      default:
        return 'wi-day-sunny';
    }
  }
}
