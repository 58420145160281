export const environment = {
    production: true,
    isDemo: false,
    useBuildingNumberAsBoardKey: true,
    singleBuildingModeBoardKey: '00011',
    portegoWebserviceUrl: 'https://portego.gat12.at/api/',
    openWeatherMapApiKey: '01d9b3ea31b841b7411e3718430cef84',
    htmlDocumentTitle: 'Schwarzes Brett',
    hideCompanyLogo: true,
    links: []
};
