import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnnouncementsModel } from 'src/app/models/announcements-model';
import { AppointmentModel } from 'src/app/models/appointment-model';
import { ContactModel } from 'src/app/models/contacts/contact-model';
import { DateTime } from 'src/app/helper/datetime';
import { PortegoService } from 'src/app/services/portego.service';
import { AnouncementIconService } from 'src/app/services/anouncement-icon.service';
import { AppoinmentIconService } from 'src/app/services/appointment-icon.service';
import { EContactType } from 'src/app/models/e-contact-type.enum';
import { GeneralContentModel } from 'src/app/models/general-content/general-content-model';
import { environment } from 'src/environments/environment';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap/carousel/carousel';
import { of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SlideInfoModel } from 'src/app/models/slideInfo-model';
import { HttpClient } from '@angular/common/http';
import { KioskModeService } from 'src/app/services/kiosk-mode.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {
  rulesCarousel: NgbCarousel;
  idBuilding: number;
  buildingNumber: string;
  weekday: string;
  announcements: AnnouncementsModel[];
  announcement: AnnouncementsModel;
  hasAnnouncements: boolean;
  hasAnnouncement: boolean;
  appointments: AppointmentModel[];
  appointment: AppointmentModel;
  hasAppointments: boolean;
  hasAppointment: boolean;
  shouldRenderAppointments: boolean;
  shouldRenderAnnouncements: boolean;
  currentDay: string;
  yourDayGuid: string;
  contacts: ContactModel[];
  emergencyContacts: ContactModel[];
  rules: string[];
  fireSafetyRules: string[];
  keycode: string;
  isLoading: boolean;
  dateTimeService: DateTime = new DateTime();
  isDemo: boolean;
  customerData: any;
  isKioskMode: boolean;

  yourDaySlideInfo: SlideInfoModel;
  contactsSlideInfo: SlideInfoModel;
  emergencyContactsSlideInfo: SlideInfoModel;
  announcementsSlideInfo: SlideInfoModel;
  appointmentsSlideInfo: SlideInfoModel;
  rulesSlideInfo: SlideInfoModel;
  fireSafetySlideInfo: SlideInfoModel;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private portegoService: PortegoService,
    private iconService: AnouncementIconService,
    private appoinmentIconService: AppoinmentIconService,
    private carouselConfig: NgbCarouselConfig,
    private httpClient: HttpClient,
    private kioskModeService: KioskModeService,
  ) {

    this.isDemo = false || environment.isDemo;
    this.keycode = this.route.snapshot.params.keycode;
    this.currentDay = new DateTime().getCurrentDate();
    this.weekday = new Date().toLocaleString('de-AT', { weekday: 'long' });
    if (this.isDemo && 'demo' === this.route.snapshot.routeConfig.path) {
      this.idBuilding = 901;
    } else if (environment.useBuildingNumberAsBoardKey) {
      this.buildingNumber = this.route.snapshot.params.boardKey;
    } else {
      this.idBuilding = this.route.snapshot.params.boardKey;
    }

    this.carouselConfig.interval = 12000;
    this.carouselConfig.wrap = true;
    this.carouselConfig.pauseOnHover = true;
    this.carouselConfig.showNavigationArrows = false;

    this.yourDaySlideInfo = {
      currentSlideNumber: 1,
      totalSlideCount: 2
    };
  }

  ngOnInit() {
    this.getCustomerData();

    this.getIdBuilding().subscribe((idBuilding: number) => {
      this.idBuilding = idBuilding;
      this.getHouseRules();
      this.getFireSafetyRules();
      this.getAnnouncements();
      this.getContacts();
      this.getAppointments();
    }, (error: any) => {
      console.log(error);
      this.navigateToSearch();
    });

    this.isKioskMode = this.kioskModeService.isKioskMode;
  }

  getIdBuilding() {
    if (environment.useBuildingNumberAsBoardKey) {
      return this.portegoService.getIdBuildingFromBuildingNumber(this.buildingNumber);
    }

    return this.portegoService.existsBuildingWithId(this.idBuilding).pipe(switchMap((exists: boolean) => {
      if (exists) {
        return of(this.idBuilding);
      }
      return throwError('no building with id ' + this.idBuilding + ' found');
    }));

  }

  getHouseRules() {
    this.portegoService.getHouseRules(this.idBuilding).subscribe(data => {
      const generalContent = data as GeneralContentModel[];
      const rules = generalContent.filter(p => p.categoryKey === 'HOUSERULES').map(p => p.image);
      this.rules = rules;

      if (rules.length > 1) {
        this.rulesSlideInfo = {
          currentSlideNumber: 1,
          totalSlideCount: rules.length,
        };
      }
    });
  }

  getFireSafetyRules() {
    this.portegoService.getHouseRules(this.idBuilding).subscribe(data => {
      const generalContent = data as GeneralContentModel[];
      const rules = generalContent.filter(p => p.categoryKey === 'FIRESAFETY').map(p => p.image);
      this.fireSafetyRules = rules;

      if (rules.length > 1) {
        this.fireSafetySlideInfo = {
          currentSlideNumber: 1,
          totalSlideCount: rules.length,
        };
      }
    });
  }

  getContacts() {
    this.portegoService.getContacts(this.idBuilding).subscribe(data => {
      const allContacts = data as ContactModel[];
      this.contacts = allContacts.filter(p => p.eType === EContactType.Contact);
      this.emergencyContacts = allContacts.filter(p => p.eType === EContactType.EmergencyContact);

      if (this.contacts.length > 1) {
        this.contactsSlideInfo = {
          currentSlideNumber: 1,
          totalSlideCount: this.contacts.length
        };
      }

      // Emergency Contacts have a default slide
      if (this.emergencyContacts.length > 0) {
        this.emergencyContactsSlideInfo = {
          currentSlideNumber: 1,
          totalSlideCount: this.emergencyContacts.length + 1
        };
      }
    });
  }
  getAnnouncements() {
    this.portegoService.getAnnouncements(this.idBuilding, this.keycode).subscribe(data => {
      const announcements = data as AnnouncementsModel[];
      for (const a of announcements) {
        if (a.publishedOn != null) {
          const date = new Date(a.publishedOn);
          a.publishedOnLabel = this.dateTimeService.getFormattedDate(date);
          a.backButton = true;
          a.typeIconUrl = this.iconService.getIconPath(a.type);
        }
      }
      this.announcements = announcements;
      this.hasAnnouncements = this.announcements.length > 0;
      this.hasAnnouncement = this.announcements.length === 1;
      this.shouldRenderAnnouncements = this.announcements.length > 1;
      if (this.hasAnnouncement) {
        this.announcement = this.announcements[0];
        this.announcement.backButton = false;
      }
      if (this.shouldRenderAnnouncements) {
        this.announcementsSlideInfo = {
          currentSlideNumber: 1,
          totalSlideCount: this.announcements.length + 1
        };
      }
    });
  }
  getAppointments() {
    this.portegoService.getAppointments(this.idBuilding, this.keycode).subscribe(data => {
      this.appointments = data as AppointmentModel[];
      for (const a of this.appointments) {
        a.backButton = true;
        const currentDate = new Date();
        const today = this.dateTimeService.getFormattedDate(currentDate);
        currentDate.setDate(currentDate.getDate() + 1);
        const tomorrow = this.dateTimeService.getFormattedDate(currentDate);
        a.highlighted = (a.dateLabel === today || a.dateLabel === tomorrow);
        a.typeIconUrl = this.appoinmentIconService.getIconPath(a.appointmentTypeCode);
      }
      this.hasAppointment = this.appointments.length === 1;
      this.hasAppointments = this.appointments.length > 0;
      this.shouldRenderAppointments = this.appointments.length > 1;
      if (this.hasAppointment) {
        this.appointment = this.appointments[0];
        this.appointment.backButton = false;
      }
      if (this.shouldRenderAppointments) {
        this.appointmentsSlideInfo = {
          currentSlideNumber: 1,
          totalSlideCount: this.appointments.length + 1
        };
      }
    });
  }

  async getCustomerData() {
    this.httpClient.get('/assets/data/customerData.json').subscribe(data => {
      this.customerData = data;
    });
  }

  afterSlide(event: NgbSlideEvent, slideInfo: SlideInfoModel) {
    slideInfo.currentSlideNumber = +event.current.substr(event.current.lastIndexOf('-') + 1) + 1;
  }

  carouselSlideToFirst(carousel: NgbCarousel) {
    carousel.select(carousel.slides.first.id);
  }

  carouselSlideTo(carousel: NgbCarousel, slideId: string) {
    carousel.select(slideId);
  }

  downloadFitnessRules(event){
    if(this.kioskModeService.isKioskMode){
      this.kioskModeService.openModal(event.target.href)
      return false;
    }

    return true;
  }

  private navigateToSearch() {
    localStorage.removeItem('board-key');
    this.router.navigate(['/search']);
  }
}
