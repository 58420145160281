import { EAnnouncementType } from '../models/eannouncement-type.enum';
import {EDisplayRestriction} from '../models/edisplay-restriction.enum';
import {EAnnouncementState} from '../models/eannouncement-state.enum';
import { FileModel } from './file-model';
export class AnnouncementsModel {
    id: number;
    title: string;
    subtitle: string;
    type: EAnnouncementType;
    typeIconUrl: string;
    content: string;
    displayRestriction: EDisplayRestriction;
    displayRestrictionLabel: string;
    publishedOn?: Date;
    publishedOnLabel: string;
    expiredOn?: Date;
    state: EAnnouncementState;
    stateLabel: string;
    pageTile: string;
    pageSubtitle: string[];
    backButton: boolean;
    imageBase64: string;
    attachment: FileModel;
}

