import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { OnInit, OnDestroy, Component, ChangeDetectionStrategy } from '@angular/core';
import { NgbCarousel, NgbSlide, NgbSlideEvent, NgbSlideEventDirection, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ESlideState } from './ESlideState';

@Component({
  selector: 'app-carousel',
  animations: [
    trigger('slide', [
      state('hidden, previousLeft, previousRight', style({
        display: 'none',
        opacity: 0
      })),
      transition(':enter', [
        style({
          display: 'none'
        }),
        animate('0s')
      ]),
      transition('* => *', [
        style({
          display: 'block'
        }),
        animate('0.6s 0s ease')
      ])
    ])
  ],
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent extends NgbCarousel implements OnInit, OnDestroy {

  private slideStates: { [id: string]: BehaviorSubject<ESlideState> } = {};
  private slidesSubscription: Subscription;

  getSlideById(slideId: string): NgbSlide {
    return this.slides.find(slide => slide.id === slideId) as NgbSlide;
  }

  getSlideIndexById(slideId: string): number {
    return this.slides.toArray().indexOf(this.getSlideById(slideId));
  }

  getSlideEventDirection(currentActiveSlideId: string, nextActiveSlideId: string): NgbSlideEventDirection {
    const currentIndex = this.getSlideIndexById(currentActiveSlideId);
    const nextIndex = this.getSlideIndexById(nextActiveSlideId);
    const lastIndex = this.slides.length - 1;
    const firstIndex = 0;

    return currentIndex <= nextIndex &&
      !(currentIndex === firstIndex && nextIndex === lastIndex) ||
      currentIndex === lastIndex && nextIndex === firstIndex
      ? NgbSlideEventDirection.LEFT
      : NgbSlideEventDirection.RIGHT;
  }

  getSlideState(id: string): Observable<ESlideState> {
    let result = this.slideStates[id];
    if (!result) {
      result = new BehaviorSubject(this.getSlideStateAtSlide(id, this.activeId));
      this.slideStates[id] = result;
    }
    return result;
  }

  getSlideStateAtSlide(id: string, current: string, prev?: string): ESlideState {
    let result: ESlideState;
    if (!prev) {
      result = current === id ? ESlideState.Active : ESlideState.Hidden;
    } else {
      const direction = this.getSlideEventDirection(prev, current);
      switch (direction) {
        case NgbSlideEventDirection.LEFT:
          result = current === id ? ESlideState.ActiveLeft : prev === id ? ESlideState.PreviousLeft : ESlideState.Hidden;
          break;
        case NgbSlideEventDirection.RIGHT:
          result = current === id ? ESlideState.ActiveRight : prev === id ? ESlideState.PreviousRight : ESlideState.Hidden;
          break;
        default:
          result = ESlideState.Hidden;
          break;
      }
    }
    return result;
  }

  ngOnInit() {
    this.slidesSubscription = this.slide.subscribe((event: NgbSlideEvent) => {
      for (const slideId of Object.keys(this.slideStates)) {
        const slideState = this.slideStates[slideId];
        slideState.next(this.getSlideStateAtSlide(slideId, event.current, event.prev));
      }
    });
  }

  ngOnDestroy() {
    this.slidesSubscription.unsubscribe();
  }

  private swipeCoord?: [number, number];
  private swipeTime?: number;

  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 1000
        && Math.abs(direction[0]) > 30 // Long enough
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        if (swipe == 'next') this.next();
        else this.prev();
      }
    }
  }
}