import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppoinmentIconService {
  iconPath: string;
  constructor() {
    this.iconPath = '../../assets/images/appointmenticons/';
  }

  getIconPath(keycode: string) {

    switch (keycode) {
      case 'Information':
        return this.iconPath + 'Information.png';
      case 'CleaningUndergroundCarPark':
        return this.iconPath + 'CleaningUndergroundCarPark.png';
      case 'Cleaning':
        return this.iconPath + 'Cleaning.png';
      case 'ChimneySweep':
        return this.iconPath + 'ChimneySweep.png';
      case 'Garbage':
        return this.iconPath + 'Garbage.png';
      case 'PropertyInspection':
        return this.iconPath + 'PropertyInspection.png';
      case 'Reading':
        return this.iconPath + 'Reading.png';
      case 'Housemeeting':
        return this.iconPath + 'HouseMeeting.png';
      case 'Children':
        return this.iconPath + 'Children.png';
      case 'ImportantInformation':
        return this.iconPath + 'ImportantInformation.png';
      default:
        return this.iconPath + 'Information.png';
    }
  }
}
