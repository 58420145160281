import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { AnnouncementsModel } from 'src/app/models/announcements-model';
import { DownloadService } from 'src/app/services/download.service';
import { KioskModeService } from 'src/app/services/kiosk-mode.service';

@Component({
  selector: 'app-topic-tile',
  templateUrl: './topic-tile.component.html',
  styleUrls: ['./topic-tile.component.less']
})
export class TopicTileComponent implements OnInit {
  @Input() model: AnnouncementsModel;
  @Output() backClick = new EventEmitter();
  constructor(
    private downloadService: DownloadService,
    private kioskModeService: KioskModeService,
  ) { }

  ngOnInit() {

  }

  @HostListener('click', ['$event'])
  onClick(event) {
    if (!this.kioskModeService.isKioskMode) {
      // When not in kiosk mode, do everything as normal. Don't intercept click
      return true;
    }

    if (event.target.tagName === 'A' && event.target.href
      && this.isExternalHref(event.target.href)) {
      this.kioskModeService.openModal(event.target.href);
    }

    // returning false here is equal to event.preventDefault()
    return false;
  }

  downloadAttachment() {
    this.downloadService.downloadFile(this.model.attachment);
    return false;
  }

  backClicked() {
    this.backClick.emit(null);
  }

  private getRootUrl(): string {
    return window.location.origin
      ? window.location.origin + '/'
      : window.location.protocol + '/' + window.location.host + '/';
  }

  private isExternalHref(href: string) {
    return href != this.getRootUrl()
  }
}
