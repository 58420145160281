import { ErrorHandler, Injectable} from '@angular/core';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {
    if('message' in error)
      console.error(`Error: ${error.message}`, error);
    else
      console.error('Undefined Error: ', error);
  }
}
