import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DownloadService } from 'src/app/services/download.service';
import { AppointmentModel } from 'src/app/models/appointment-model';

@Component({
  selector: 'app-appointment-tile',
  templateUrl: './appointment-tile.component.html',
  styleUrls: ['./appointment-tile.component.less']
})
export class AppointmentTileComponent implements OnInit {
  @Input() model: AppointmentModel;
  @Output() backClick = new EventEmitter();
  constructor(private downloadService: DownloadService) {
  }

  ngOnInit() {
  }

  backClicked() {
    this.backClick.emit(null);
  }

  downloadAttachment() {
    this.downloadService.downloadFile(this.model.attachment);
    return false;
  }
}
