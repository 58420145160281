import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.less']
})
export class ImprintComponent implements OnInit {
  location: Location;

  constructor() { }

  ngOnInit() {
    this.location = location;
  }

}
