import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AnnouncementsModel } from 'src/app/models/announcements-model';

@Component({
  selector: 'app-topic-list-tile',
  templateUrl: './topic-list-tile.component.html',
  styleUrls: ['./topic-list-tile.component.less']
})
export class TopicListTileComponent implements OnInit {
  @Input() models: AnnouncementsModel[];
  @Output() moreClick = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  moreClicked(slideId: string) {
    this.moreClick.emit(slideId);
  }
}
