import { Component, OnInit } from '@angular/core';
import { NameDayModel } from 'src/app/models/name-day-model';
import { SloganModel } from 'src/app/models/slogan-model';
import { PortegoService } from 'src/app/services/portego.service';
import { DateTime } from 'src/app/helper/datetime';


@Component({
  selector: 'app-day-names-motto-tile',
  templateUrl: './day-names-motto-tile.component.html',
  styleUrls: ['./day-names-motto-tile.component.less']
})
export class DayNamesMottoTileComponent implements OnInit {
  names: string;
  slogan: string;
  nameDays: NameDayModel[];
  slogans: SloganModel[];
  day: number;
  month: number;
  week: number;

  constructor(private portegoService: PortegoService) {
    this.portegoService = portegoService;
  }

  ngOnInit() {
    this.setCurrentDate();
    this.getNamesOfTheDay();
    this.getSloganOfTheWeek();
  }

  setCurrentDate() {
    const today = new Date();
    this.day = today.getDate();
    this.month = today.getMonth() + 1;
    this.week = new DateTime().getCurrentWeekNumber();
  }

  getNamesOfTheDay() {

    this.portegoService.getNamesOfTheDay().subscribe(data => {
      this.nameDays = data as NameDayModel[];
      this.names = this.nameDays.find(p => p.day === this.day && p.month === this.month).text;
    });
  }
  getSloganOfTheWeek() {

    this.portegoService.getSloganOfTheWeek().subscribe(data => {
      this.slogans = data as SloganModel[];
      this.slogan = this.slogans.find(p => p.week === this.week).text;
    });
  }

}
