import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';

@Component({
  selector: 'app-kiosk-modal',
  templateUrl: './kiosk-modal.component.html',
  styleUrls: ['./kiosk-modal.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class KioskModalComponent implements OnInit {
  @Input() url;

  constructor(
    public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit(): void {
    // GAT 12 requirement: Close modal after 20s
   timer(20_000).subscribe(() => this.activeModal.close());
  }
}
