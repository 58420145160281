import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
// https://medium.com/@motcowley/angular-loading-screen-for-module-navigation-ac96c056b410
export class AppComponent implements OnInit, AfterViewInit {
  isLoading: boolean;
  hideCompanyLogo: boolean;

  constructor(private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.isLoading = true;
    document.title = environment.htmlDocumentTitle;
    this.hideCompanyLogo = environment.hideCompanyLogo;
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }
}
