import { Component, OnInit, Input } from '@angular/core';
import { BuildingAddressModel } from 'src/app/models/building-address-model';
import { PortegoService } from 'src/app/services/portego.service';

@Component({
  selector: 'app-house-number-plaque',
  templateUrl: './house-number-plaque.component.html',
  styleUrls: ['./house-number-plaque.component.less']
})
export class HouseNumberPlaqueComponent implements OnInit {
  @Input() idBuilding: number;
  model: BuildingAddressModel;
  constructor(private portegoService: PortegoService) {
  }
  ngOnInit() {
    this.model = new BuildingAddressModel();
    this.portegoService.getBuildingAdress(this.idBuilding).subscribe(data => {
      this.model = data as BuildingAddressModel;
    });
  }
}
