import { TemperatureModel } from './temperature-model';
import { WeatherModel } from './weather-model';

export class WeatherOfTheDayModel {
    temp: TemperatureModel;
    weather: WeatherModel;
    iconClass: string;

    constructor() {
      this.temp = new TemperatureModel();
      this.weather = new WeatherModel();
    }
}
