import { Component, OnInit, AfterViewInit, Input, OnChanges } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { WeatherOfTheDayModel } from 'src/app/models/weather/weather-of-the-day-model';
import { OpenWeatherMap } from 'src/app/helper/openWeatherMap';
import { PortegoService } from 'src/app/services/portego.service';
import { DateTime } from 'src/app/helper/datetime';
import { BuildingAddressModel } from 'src/app/models/building-address-model';

@Component({
  selector: 'app-day-overview-tile',
  templateUrl: './day-overview-tile.component.html',
  styleUrls: ['./day-overview-tile.component.less']
})

export class DayOverviewTileComponent implements OnInit, AfterViewInit {
  @Input() idBuilding: number;
  currentDay: string;
  city: string;
  date: Date;
  model: WeatherOfTheDayModel;

  constructor(private weatherProvider: OpenWeatherMap, private portegoService: PortegoService) {
    this.currentDay = new DateTime().getCurrentDate();
    this.model = new WeatherOfTheDayModel();
    this.date = new Date();
  }

  ngOnInit() {
    this.portegoService.getBuildingAdress(this.idBuilding).subscribe(data => {
      const address = data as BuildingAddressModel;
      this.city = isNullOrUndefined(address.city) ? 'Denpasar' : address.city;
      this.initLocalWeather();
    });
  }
  ngAfterViewInit(): void {
    this.initLocalClocks();
  }

  initLocalWeather() {
    this.weatherProvider.getData(this.city).then(data => {
      const model = new WeatherOfTheDayModel();
      // model.temp = data[1]['list'][0].temp;
      model.weather = data[0]['weather'][0];
      model.temp.day = Math.round(data[0]['main'].temp);
      // model.temp.eve = Math.round(model.temp.eve);
      // model.temp.morn = Math.round(model.temp.morn);
      // model.temp.night = Math.round(model.temp.night);
      model.iconClass = this.weatherProvider.getIconPath(data[0]['weather'][0].id);
      this.model = model;
    });
  }

  initLocalClocks() {
    const date = this.date;
    const seconds = date.getSeconds();
    const minutes = date.getMinutes();
    const hours = date.getHours();
    const hands = [
      {
        hand: 'hours',
        angle: (hours * 30) + (minutes / 2)
      },
      {
        hand: 'minutes',
        angle: (minutes * 6)
      },
      {
        hand: 'seconds',
        angle: (seconds * 6)
      }
    ];
    for (let j = 0; j < hands.length; j++) {
      const elements = document.querySelectorAll('.' + hands[j].hand);
      elements.forEach((element) => {
        element.setAttribute('style', '-webkit-transform: rotateZ(' + hands[j].angle + 'deg)');
        element.setAttribute('transform', 'rotateZ(' + hands[j].angle + 'deg)');
        element.setAttribute('transform', 'rotateZ(' + hands[j].angle + 'deg)');
        // If this is a minute hand, note the seconds position (to calculate minute position later)
        if (hands[j].hand === 'minutes') {
          element.parentElement.setAttribute('data-second-angle', hands[j + 1].angle.toString());
        }
      });
    }
  }

  /*
 * Set a timeout for the first minute hand movement (less than 1 minute), then rotate it every minute after that
 */
  setUpMinuteHands() {
    // Find out how far into the minute we are
    const containers = document.querySelectorAll('.minutes-container');
    const secondAngle = +(containers[0].getAttribute('data-second-angle'));
    if (secondAngle > 0) {
      // Set a timeout until the end of the current minute, to move the hand
      const delay = (((360 - secondAngle) / 6) + 0.1) * 1000;
      setTimeout(function () {
        this.moveMinuteHands(containers);
      }, delay);
    }
  }

  /*
   * Do the first minute's rotation
   */
  moveMinuteHands(containers: any): void {
    for (let i = 0; i < containers.length; i++) {
      containers[i].setAttribute('style', '-webkit-transform: rotateZ(6deg)');
      containers[i].setAttribute('transform', 'rotateZ(6deg)');
    }
    // Then continue with a 60 second interval
    setInterval(function () {
      for (let i = 0; i < containers.length; i++) {
        if (containers[i].angle === undefined) {
          containers[i].angle = 12;
        } else {
          containers[i].angle += 6;
        }
        containers[i].style.webkitTransform = 'rotateZ(' + containers[i].angle + 'deg)';
        containers[i].style.transform = 'rotateZ(' + containers[i].angle + 'deg)';
      }
    }, 60000);
  }
}
